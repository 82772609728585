import { BatchUpsertFrequencyLimitationCategoriesRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const batchUpsertFrequencyLimitationCategories = makeMutation({
  mutationKey: ["practices", "batchUpsertFrequencyLimitationCategories"],
  formatParams: (args: { practiceId: number; data: BatchUpsertFrequencyLimitationCategoriesRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFrequencyLimitationCategories"), { practiceId }]);
    },
  }),
});
