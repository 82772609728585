import { UpsertFrequencyLimitationCategoryRequest } from "@libs/api/generated-api";

type SecretLimitationCategoryRequest = UpsertFrequencyLimitationCategoryRequest & {
  // This parameter is hidden in api generation, as it is only used for admin portal, and cannot be set after bootstrapping for any other categories
  isDefault: true;
};

export const defaultFrequencyLimitationCategories: SecretLimitationCategoryRequest[] = [
  {
    group: "DIAGNOSTIC",
    isDefault: true,
    dentalProcedureIds: [17, 18, 19, 20],
    defaultFrequencyLimit: "X_VISITS_PER_BENEFIT_YEAR",
    name: "BWs",
  },
  {
    group: "DIAGNOSTIC",
    isDefault: true,
    dentalProcedureIds: [11, 26],
    defaultFrequencyLimit: "ONE_VISIT_EVERY_X_YEARS",
    name: "Pano/FMX",
  },
  {
    group: "DIAGNOSTIC",
    isDefault: true,
    dentalProcedureIds: [1, 4],
    defaultFrequencyLimit: "X_VISITS_PER_BENEFIT_YEAR",
    name: "Exams",
  },
  {
    group: "DIAGNOSTIC",
    isDefault: true,
    dentalProcedureIds: [60],
    defaultFrequencyLimit: "ONE_VISIT_EVERY_X_MONTHS",
    name: "Cancer Screenings",
  },
  {
    group: "PREVENTIVE",
    isDefault: true,
    dentalProcedureIds: [96, 97],
    defaultFrequencyLimit: "X_VISITS_PER_BENEFIT_YEAR",
    name: "Prophylaxis",
  },
  {
    group: "PREVENTIVE",
    isDefault: true,
    dentalProcedureIds: [98, 99],
    defaultFrequencyLimit: "X_VISITS_PER_BENEFIT_YEAR",
    name: "Fluoride",
  },
  {
    group: "PREVENTIVE",
    isDefault: true,
    dentalProcedureIds: [104],
    defaultFrequencyLimit: "ONE_VISIT_EVERY_X_YEARS_PER_TOOTH",
    name: "Sealants",
  },
  {
    group: "RESTORATIVE",
    isDefault: true,
    dentalProcedureIds: [169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181],
    defaultFrequencyLimit: "ONE_VISIT_EVERY_X_YEARS_PER_TOOTH",
    name: "Crowns",
  },
  {
    group: "PERIODONTAL",
    isDefault: true,
    dentalProcedureIds: [287, 288],
    defaultFrequencyLimit: "ONE_VISIT_EVERY_X_MONTHS_PER_QUADRANT",
    name: "SRP",
  },
  {
    group: "PERIODONTAL",
    isDefault: true,
    dentalProcedureIds: [290],
    defaultFrequencyLimit: "ONE_VISIT_EVERY_X_YEARS",
    name: "Full Debridement",
  },
  {
    group: "PERIODONTAL",
    isDefault: true,
    dentalProcedureIds: [292],
    defaultFrequencyLimit: "X_VISITS_PER_BENEFIT_YEAR",
    name: "Perio Maintenance",
  },
  {
    group: "PROSTHODONTICS",
    isDefault: true,
    dentalProcedureIds: [296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309],
    name: "Denture/Partials",
  },
  {
    group: "IMPLANTS",
    isDefault: true,
    dentalProcedureIds: [402],
    name: "Implant",
  },
];
