import { AutomationActionRequest } from "@libs/api/generated-api";

export type AutomationJourneyRequest = {
  name: string;
  type: string;
  actions: AutomationActionRequest[];
};
export const defaultJourneyActions: AutomationJourneyRequest[] = [
  {
    name: "Pre Appointment",
    type: "APPOINTMENT",
    actions: [
      {
        event: "CONFIRMED_APPOINTMENT",
        eventTime: "BEFORE",
        interval: 7,
        intervalType: "DAY",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, friendly reminder that your appointment at {{PRACTICE_NAME}} is scheduled for {{APPOINTMENT_TIME}} on {{APPOINTMENT_DATE}}.",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, friendly reminder that your appointment at {{PRACTICE_NAME}} is scheduled for {{APPOINTMENT_TIME}} on {{APPOINTMENT_DATE}}.",
        subject: "Reminder for your appointment with {{PRACTICE_NAME}}",
        active: false,
      },
      {
        event: "UNCONFIRMED_APPOINTMENT",
        eventTime: "BEFORE",
        interval: 7,
        intervalType: "DAY",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, your appointment with {{PRACTICE_NAME}} is scheduled for {{APPOINTMENT_TIME}} on {{APPOINTMENT_DATE}}. To confirm your appointment, click here: {{CONFIRM_APPOINTMENT_LINK}}",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, your appointment with {{PRACTICE_NAME}} is scheduled for {{APPOINTMENT_TIME}} on {{APPOINTMENT_DATE}}. To confirm your appointment, click here: {{CONFIRM_APPOINTMENT_LINK}}",
        subject: "Confirm your appointment with {{PRACTICE_NAME}}",
        active: false,
      },
      {
        event: "UNCONFIRMED_APPOINTMENT",
        eventTime: "BEFORE",
        interval: 3,
        intervalType: "DAY",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, your appointment with {{PRACTICE_NAME}} is scheduled for {{APPOINTMENT_TIME}} on {{APPOINTMENT_DATE}}. To confirm your appointment, click here: {{CONFIRM_APPOINTMENT_LINK}}",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, your appointment with {{PRACTICE_NAME}} is scheduled for {{APPOINTMENT_TIME}} on {{APPOINTMENT_DATE}}. To confirm your appointment, click here: {{CONFIRM_APPOINTMENT_LINK}}",
        subject: "Reminder: Confirm your appointment with {{PRACTICE_NAME}}",
        active: false,
      },
      {
        event: "CONFIRMED_APPOINTMENT",
        eventTime: "BEFORE",
        interval: 1,
        intervalType: "DAY",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, we look forward to seeing you tomorrow at {{PRACTICE_NAME}} at {{APPOINTMENT_TIME}}.",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, we look forward to seeing you tomorrow at {{PRACTICE_NAME}} at {{APPOINTMENT_TIME}}.",
        subject: "Your upcoming appointment with {{PRACTICE_NAME}}",
        active: false,
      },
      {
        event: "UNCONFIRMED_APPOINTMENT",
        eventTime: "BEFORE",
        interval: 1,
        intervalType: "DAY",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, please confirm your appointment with {{PRACTICE_NAME}} tomorrow at {{APPOINTMENT_TIME}}. To confirm your appointment, click here: {{CONFIRM_APPOINTMENT_LINK}}",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, please confirm your appointment with {{PRACTICE_NAME}} tomorrow at {{APPOINTMENT_TIME}}. To confirm your appointment, click here: {{CONFIRM_APPOINTMENT_LINK}}",
        subject: "Reminder: Confirm your appointment with {{PRACTICE_NAME}}",
        active: false,
      },
    ],
  },
  {
    name: "Post Appointment",
    type: "POST_APPOINTMENT",
    actions: [
      {
        event: "APPOINTMENT_COMPLETED",
        eventTime: "AFTER",
        interval: 1,
        intervalType: "HOUR",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, thanks for visiting {{PRACTICE_NAME}}. If you enjoyed your visit, please leave us a review!",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, thanks for visiting {{PRACTICE_NAME}}. If you enjoyed your visit, please leave us a review!",
        subject: "Thanks for visiting {{PRACTICE_NAME}}",
        active: false,
      },
    ],
  },
  {
    name: "Recall",
    type: "RECALL",
    actions: [
      {
        event: "PROPHY_DUE",
        eventTime: "BEFORE",
        interval: 6,
        intervalType: "WEEK",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, you are due for your next dental cleaning and exam with {{PRACTICE_NAME}} on {{RECALL_DUE_DATE}}. Respond with some times that work for you, or call us at {{PRACTICE_PHONE_NUMBER}}.",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, you are due for your next dental cleaning and exam with {{PRACTICE_NAME}} on {{RECALL_DUE_DATE}}. Respond with some times that work for you, or call us at {{PRACTICE_PHONE_NUMBER}}.",
        subject: "It's time to schedule your next appointment with {{PRACTICE_NAME}}",
        sendTime: "09:00:00",
        active: false,
      },
      {
        event: "PERIO_DUE",
        eventTime: "BEFORE",
        interval: 4,
        intervalType: "WEEK",
        channels: ["SMS"],
        smsTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, you are due for your next periodontal maintenance appointment with {{PRACTICE_NAME}} on {{RECALL_DUE_DATE}}. Respond with some times that work for you, or call us at {{PRACTICE_PHONE_NUMBER}}.",
        emailTemplate:
          "Hi {{PATIENT_PREFERRED_NAME}}, you are due for your next periodontal maintenance appointment with {{PRACTICE_NAME}} on {{RECALL_DUE_DATE}}. Respond with some times that work for you, or call us at {{PRACTICE_PHONE_NUMBER}}.",
        subject: "It's time to schedule your next appointment with {{PRACTICE_NAME}}",
        sendTime: "09:00:00",
        active: false,
      },
    ],
  },
  {
    name: "Incomplete Forms",
    type: "FORMS",
    actions: [
      {
        event: "CONFIRMED_APPOINTMENT",
        eventTime: "BEFORE",
        interval: 1,
        intervalType: "DAY",
        channels: ["SMS"],
        smsTemplate:
          "Please complete the forms required for tomorrow's appointment with {{PRACTICE_NAME}} here: {{FORMS_LINK}}",
        emailTemplate:
          "Please complete the forms required for tomorrow's appointment with {{PRACTICE_NAME}} here: {{FORMS_LINK}}",
        subject: "Forms for your upcoming appointment with {{PRACTICE_NAME}}",
        active: false,
      },
      {
        event: "CONFIRMED_APPOINTMENT",
        eventTime: "BEFORE",
        interval: 1,
        intervalType: "HOUR",
        channels: ["SMS"],
        smsTemplate:
          "Please complete the forms required for today's appointment with {{PRACTICE_NAME}} here: {{FORMS_LINK}}",
        emailTemplate:
          "Please complete the forms required for today's appointment with {{PRACTICE_NAME}} here: {{FORMS_LINK}}",
        subject: "Action needed: Complete your forms for your upcoming appointment",
        active: false,
      },
    ],
  },
];
