import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Form } from "@libs/components/UI/Form";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { RadioList } from "@libs/components/UI/RadioList";
import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useCallback } from "react";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useNavigate } from "react-router-dom";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { PracticeVO } from "@libs/api/generated-api";
import { AdminPanel } from "components/UI/AdminPanel";
import { paths } from "utils/routing/paths";
import { createPractice } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { useBootstrapPractice } from "components/CreatePractice/api/useBootstrapPractice";

const schema = {
  doingBusinessAs: [
    {
      $v: required,
      $error: `Doing Business As is required`,
    },
  ],
  onboardingType: [
    {
      $v: required,
      $error: "Practice type required",
    },
  ],
};

export const CreatePracticeDetailsRoute: React.FC = () => {
  const [draftDetails, updateDraftDetails] = useObjectState<
    Pick<PracticeVO, "doingBusinessAs" | "onboardingType">
  >({
    doingBusinessAs: "",
    onboardingType: "STARTUP",
  });
  const navigate = useNavigate();
  const [createPracticeMutation] = useApiMutations([createPractice]);
  const createPracticeMutate = createPracticeMutation.mutate;

  const { result: validationResult, validate } = useValidation(draftDetails, schema);
  const { handleBootstrapPractice, isLoading: isBootstrappingPractice } = useBootstrapPractice();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      const onboardingType = draftDetails.onboardingType;

      if (!validate().$isValid || !onboardingType) {
        return;
      }

      createPracticeMutate(
        {
          data: {
            ...draftDetails,
            onboardingStatus: "PROVISIONING",
            uuid: crypto.randomUUID(),
          },
        },
        {
          onSuccess: async (data) => {
            const { id: practiceId } = data.data.data;

            if (practiceId) {
              try {
                await handleBootstrapPractice(practiceId, onboardingType);
                navigate(paths.setupPracticeAdmins({ practiceId }), { replace: true });
              } catch (err) {
                handleError(err);
              }
            } else {
              throw new Error("Practice ID not found");
            }
          },
          onError: handleError,
        }
      );
    },
    [handleBootstrapPractice, createPracticeMutate, draftDetails, navigate, validate]
  );

  return (
    <Form onSubmit={handleSubmit} className="flex flex-col h-full overflow-y-auto">
      <AdminPanel>
        <TaskProgressHeader totalSteps={3} step={0} />

        <div className="flex flex-col gap-2">
          <h2 className="text-sm font-sansSemiBold">Practice Details</h2>
          <p className="text-xs">Add some basic information about the practice</p>
        </div>
        <div>
          <FormFieldInput
            label="Doing Business As"
            name="name"
            placeholder="Practice Name"
            required
            error={validationResult.doingBusinessAs.$error}
            value={draftDetails.doingBusinessAs}
            onChange={(e) => updateDraftDetails({ doingBusinessAs: e.target.value })}
          />
        </div>
        <div>
          <RadioList
            label="Type"
            name="practiceType"
            options={[
              { label: "Startup", value: "STARTUP" },
              { label: "Conversion", value: "CONVERSION" },
            ]}
            error={validationResult.onboardingType.$error}
            required
            onChange={(_, option) => updateDraftDetails({ onboardingType: option.value })}
            selectedValue={draftDetails.onboardingType}
          />
        </div>
      </AdminPanel>
      <div
        className={`
          flex
          gap-6
          justify-center
          flex-none
          p-4
          border-t
          border-t-slate-200
        `}
      >
        <ButtonInternalLink to={paths.home()} theme="secondary" className="min-w-button">
          Cancel
        </ButtonInternalLink>
        <AsyncButton
          isLoading={createPracticeMutation.isLoading || isBootstrappingPractice}
          type="submit"
          theme="primary"
          className="min-w-button"
        >
          Next
        </AsyncButton>
      </div>
    </Form>
  );
};
