import { CreateProcedureShortcutRequest } from "@libs/api/generated-api";

export type BootstrapDefaultProcedureShortcutRequest = Omit<
  CreateProcedureShortcutRequest,
  "procedureIds"
> & {
  adaCodes: string[];
};
export const defaultProcedureShortcuts: BootstrapDefaultProcedureShortcutRequest[] = [
  {
    name: "BU + PFM",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D2950", "D2750"],
  },
  {
    name: "O",
    category: "POST_COMPOSITE",
    surfaces: ["O"],
    adaCodes: ["D2391"],
  },
  {
    name: "All on 4",
    category: "IMPLANTS",
    adaCodes: ["D6010", "D6056", "D6058"],
  },
  {
    name: "Ath Guard",
    category: "APPLIANCES",
    adaCodes: ["D9941"],
  },
  {
    name: "Periodic",
    category: "EXAMS",
    adaCodes: ["D0120"],
  },
  {
    name: "Ext - Primary tooth",
    category: "ORAL_SURGERY",
    adaCodes: ["D7111"],
  },
  {
    name: "RCT Ant",
    category: "ENDO",
    adaCodes: ["D3310"],
  },
  {
    name: "F",
    category: "ANT_COMPOSITE",
    surfaces: ["B"],
    adaCodes: ["D2330"],
  },
  {
    name: "FMX",
    category: "XRAYS",
    adaCodes: ["D0210"],
  },
  {
    name: "B Abf",
    category: "AMALGAM",
    surfaces: ["B"],
    adaCodes: ["D2140"],
  },
  {
    name: "Mx Hybrid",
    category: "ADJUNCT",
    adaCodes: ["D6114", "D7310", "D7310", "D6056", "D6056", "D6056", "D6056"],
  },
  {
    name: "I",
    category: "ANT_COMPOSITE",
    surfaces: ["O"],
    adaCodes: ["D2330"],
  },
  {
    name: "Exam < 3 y.o.",
    category: "EXAMS",
    adaCodes: ["D0145"],
  },
  {
    name: "MO",
    category: "POST_COMPOSITE",
    surfaces: ["M", "O"],
    adaCodes: ["D2392"],
  },
  {
    name: "Pano",
    category: "XRAYS",
    adaCodes: ["D0330"],
  },
  {
    name: "Abut Supp Porc",
    category: "IMPLANTS",
    adaCodes: ["D6058"],
  },
  {
    name: "RCT - RPre",
    category: "ENDO",
    adaCodes: ["D3347"],
  },
  {
    name: "Ext - Simple",
    category: "ORAL_SURGERY",
    adaCodes: ["D7140"],
  },
  {
    name: "Porc Crn",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D2740", "D2950"],
  },
  {
    name: "Perio Maint",
    category: "PERIO",
    adaCodes: ["D4910"],
  },
  {
    name: "MOD",
    category: "AMALGAM",
    surfaces: ["M", "O", "D"],
    adaCodes: ["D2160"],
  },
  {
    name: "IF",
    category: "ANT_COMPOSITE",
    surfaces: ["O", "B"],
    adaCodes: ["D2331"],
  },
  {
    name: "Occlusal Guard",
    category: "APPLIANCES",
    adaCodes: ["D9944"],
  },
  {
    name: "MOB",
    category: "AMALGAM",
    surfaces: ["M", "O", "B"],
    adaCodes: ["D2160"],
  },
  {
    name: "Imp Supp Porc",
    category: "IMPLANTS",
    adaCodes: ["D6065"],
  },
  {
    name: "Ext - Surgical",
    category: "ORAL_SURGERY",
    adaCodes: ["D7210"],
  },
  {
    name: "Arestin",
    category: "PERIO",
    surfaces: ["M", "D", "B", "L"],
    adaCodes: ["D4381"],
  },
  {
    name: "DO",
    category: "POST_COMPOSITE",
    surfaces: ["O", "D"],
    adaCodes: ["D2392"],
  },
  {
    name: "PA",
    category: "XRAYS",
    adaCodes: ["D0220"],
  },
  {
    name: "Limited EX",
    category: "EXAMS",
    adaCodes: ["D0140"],
  },
  {
    name: "Porc Ret",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D6740"],
  },
  {
    name: "MOD",
    category: "POST_COMPOSITE",
    surfaces: ["M", "O", "D"],
    adaCodes: ["D2393"],
  },
  {
    name: "Abut Supp Porc",
    category: "IMPLANTS",
    adaCodes: ["D6058"],
  },
  {
    name: "DOB",
    category: "AMALGAM",
    surfaces: ["O", "D", "B"],
    adaCodes: ["D2160"],
  },
  {
    name: "Perio Maint",
    category: "PERIO",
    adaCodes: ["D4910"],
  },
  {
    name: "Consultation",
    category: "EXAMS",
    adaCodes: ["D9310"],
  },
  {
    name: "Occ G - Soft FA",
    category: "APPLIANCES",
    adaCodes: ["D9945"],
  },
  {
    name: "Ext - Impacted-Soft",
    category: "ORAL_SURGERY",
    adaCodes: ["D7220"],
  },
  {
    name: "1 BW",
    category: "XRAYS",
    adaCodes: ["D0270"],
  },
  {
    name: "L",
    category: "ANT_COMPOSITE",
    surfaces: ["L"],
    adaCodes: ["D2330"],
  },
  {
    name: "SRP 1-3",
    category: "PERIO",
    adaCodes: ["D4342"],
  },
  {
    name: "2 BW",
    category: "XRAYS",
    adaCodes: ["D0272"],
  },
  {
    name: "RCT - RAnt",
    category: "ENDO",
    adaCodes: ["D3346"],
  },
  {
    name: "IL",
    category: "ANT_COMPOSITE",
    surfaces: ["O", "L"],
    adaCodes: ["D2331"],
  },
  {
    name: "OL",
    category: "POST_COMPOSITE",
    surfaces: ["O", "L"],
    adaCodes: ["D2392"],
  },
  {
    name: "Comp Ex",
    category: "EXAMS",
    adaCodes: ["D0150"],
  },
  {
    name: "Ext - Part Bony",
    category: "ORAL_SURGERY",
    adaCodes: ["D7230"],
  },
  {
    name: "Imp Supp Porc",
    category: "IMPLANTS",
    adaCodes: ["D6065"],
  },
  {
    name: "Sleep Apnea App",
    category: "APPLIANCES",
    adaCodes: ["D9947"],
  },
  {
    name: "Ext - Complete Bony",
    category: "ORAL_SURGERY",
    adaCodes: ["D7240"],
  },
  {
    name: "3 BW",
    category: "XRAYS",
    adaCodes: ["D0273"],
  },
  {
    name: "Occ G - Hard, PA",
    category: "APPLIANCES",
    adaCodes: ["D9946"],
  },
  {
    name: "Imp Supp PFM - Hi Nob",
    category: "IMPLANTS",
    adaCodes: ["D6066"],
  },
  {
    name: "DI",
    category: "ANT_COMPOSITE",
    surfaces: ["O", "D"],
    adaCodes: ["D2331"],
  },
  {
    name: "PFM",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D2750"],
  },
  {
    name: "RCT Pre",
    category: "ENDO",
    adaCodes: ["D3320"],
  },
  {
    name: "OB",
    category: "POST_COMPOSITE",
    surfaces: ["O", "B"],
    adaCodes: ["D2392"],
  },
  {
    name: "FMD",
    category: "PERIO",
    adaCodes: ["D4355"],
  },
  {
    name: "DL",
    category: "ANT_COMPOSITE",
    surfaces: ["D", "L"],
    adaCodes: ["D2331"],
  },
  {
    name: "SRP",
    category: "PERIO",
    adaCodes: ["D4341"],
  },
  {
    name: "Fluoride",
    category: "EXAMS",
    adaCodes: ["D1208"],
  },
  {
    name: "RCT - RMol",
    category: "ENDO",
    adaCodes: ["D3348"],
  },
  {
    name: "4 BW",
    category: "XRAYS",
    adaCodes: ["D0274"],
  },
  {
    name: "MOB",
    category: "POST_COMPOSITE",
    surfaces: ["M", "O", "B"],
    adaCodes: ["D2393"],
  },
  {
    name: "Endosteal Imp",
    category: "IMPLANTS",
    adaCodes: ["D6010"],
  },
  {
    name: "MI",
    category: "ANT_COMPOSITE",
    surfaces: ["M", "O"],
    adaCodes: ["D2331"],
  },
  {
    name: "PA +",
    category: "XRAYS",
    adaCodes: ["D0230"],
  },
  {
    name: "PFM Pontic",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D6240"],
  },
  {
    name: "Pulp Cap",
    category: "ENDO",
    adaCodes: ["D3110"],
  },
  {
    name: "MOL",
    category: "POST_COMPOSITE",
    surfaces: ["M", "O", "L"],
    adaCodes: ["D2393"],
  },
  {
    name: "Fluoride",
    category: "PERIO",
    adaCodes: ["D1206"],
  },
  {
    name: "Fluor Varn - Adult",
    category: "EXAMS",
    adaCodes: ["D1206"],
  },
  {
    name: "Bone Graft",
    category: "ORAL_SURGERY",
    adaCodes: ["D7953"],
  },
  {
    name: "Prefab Abut",
    category: "IMPLANTS",
    adaCodes: ["D6056"],
  },
  {
    name: "RCT Mol",
    category: "ENDO",
    adaCodes: ["D3330"],
  },
  {
    name: "Abut Supp Cast - Hi Nob",
    category: "IMPLANTS",
    adaCodes: ["D6062"],
  },
  {
    name: "Harvest Bone",
    category: "ORAL_SURGERY",
    adaCodes: ["D7295"],
  },
  {
    name: "ML",
    category: "ANT_COMPOSITE",
    surfaces: ["M", "L"],
    adaCodes: ["D2331"],
  },
  {
    name: "MODB",
    category: "POST_COMPOSITE",
    surfaces: ["M", "O", "D", "B"],
    adaCodes: ["D2394"],
  },
  {
    name: "OBL",
    category: "AMALGAM",
    surfaces: ["O", "B", "L"],
    adaCodes: ["D2160"],
  },
  {
    name: "CBCT Max",
    category: "XRAYS",
    adaCodes: ["D0366"],
  },
  {
    name: "Abut Supp PFM - Hi Nob",
    category: "IMPLANTS",
    adaCodes: ["D6059"],
  },
  {
    name: "DLF",
    category: "ANT_COMPOSITE",
    surfaces: ["D", "B", "L"],
    adaCodes: ["D2332"],
  },
  {
    name: "Sealant",
    category: "EXAMS",
    adaCodes: ["D1351"],
  },
  {
    name: "PFM Ret - Hi Nob",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D6750"],
  },
  {
    name: "Pulpotomy",
    category: "ENDO",
    adaCodes: ["D3220"],
  },
  {
    name: "DOL",
    category: "POST_COMPOSITE",
    surfaces: ["O", "D", "L"],
    adaCodes: ["D2393"],
  },
  {
    name: "DO",
    category: "AMALGAM",
    surfaces: ["O", "D"],
    adaCodes: ["D2150"],
  },
  {
    name: "CBCT Mand",
    category: "XRAYS",
    adaCodes: ["D0365"],
  },
  {
    name: "CBCT Partial",
    category: "XRAYS",
    adaCodes: ["D0364"],
  },
  {
    name: "Imp Supp Cast - Hi Nob",
    category: "IMPLANTS",
    adaCodes: ["D6067"],
  },
  {
    name: "RCT Mol BU PFM",
    category: "ENDO",
    adaCodes: ["D3330", "D2950", "D2750"],
  },
  {
    name: "OBL",
    category: "POST_COMPOSITE",
    surfaces: ["O", "B", "L"],
    adaCodes: ["D2393"],
  },
  {
    name: "MDL",
    category: "ANT_COMPOSITE",
    surfaces: ["M", "D", "L"],
    adaCodes: ["D2332"],
  },
  {
    name: "MODB",
    category: "AMALGAM",
    surfaces: ["M", "O", "D", "B"],
    adaCodes: ["D2161"],
  },
  {
    name: "FGC Ret - Hi Nob",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D6790"],
  },
  {
    name: "MLF",
    category: "ANT_COMPOSITE",
    surfaces: ["M", "B", "L"],
    adaCodes: ["D2332"],
  },
  {
    name: "OL",
    category: "AMALGAM",
    surfaces: ["O", "L"],
    adaCodes: ["D2150"],
  },
  {
    name: "OBL",
    category: "POST_COMPOSITE",
    surfaces: ["O", "B", "L"],
    adaCodes: ["D2393"],
  },
  {
    name: "FGCrn",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D2790"],
  },
  {
    name: "4BW + 3PA",
    category: "XRAYS",
    adaCodes: ["D0274", "D0220", "D0230", "D0230"],
  },
  {
    name: "Custom Abut",
    category: "IMPLANTS",
    adaCodes: ["D6057"],
  },
  {
    name: "MO",
    category: "AMALGAM",
    surfaces: ["M", "O"],
    adaCodes: ["D2150"],
  },
  {
    name: "FGC Pontic",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D6210"],
  },
  {
    name: "Implant abutment and crown",
    category: "IMPLANTS",
    adaCodes: ["D6010", "D6057", "D6058"],
  },
  {
    name: "IFL",
    category: "ANT_COMPOSITE",
    surfaces: ["O", "B", "L"],
    adaCodes: ["D2332"],
  },
  {
    name: "DOB",
    category: "POST_COMPOSITE",
    surfaces: ["O", "D", "B"],
    adaCodes: ["D2393"],
  },
  {
    name: "Implant, Custom Abutment, Crn",
    category: "IMPLANTS",
    adaCodes: ["D0383", "D6010", "D6058", "D6057"],
  },
  {
    name: "MODL",
    category: "POST_COMPOSITE",
    surfaces: ["M", "O", "D", "L"],
    adaCodes: ["D2394"],
  },
  {
    name: "Porc Pontic",
    category: "CROWNS_OR_BRIDGES",
    surfaces: ["M", "O", "D", "B", "L"],
    adaCodes: ["D6245"],
  },
  {
    name: "OB",
    category: "AMALGAM",
    surfaces: ["O", "B"],
    adaCodes: ["D2150"],
  },
  {
    name: "DIFL",
    category: "ANT_COMPOSITE",
    surfaces: ["O", "D", "B", "L"],
    adaCodes: ["D2335"],
  },
  {
    name: "MIFL",
    category: "ANT_COMPOSITE",
    surfaces: ["M", "O", "B", "L"],
    adaCodes: ["D2335"],
  },
  {
    name: "MOL",
    category: "AMALGAM",
    surfaces: ["M", "O", "L"],
    adaCodes: ["D2160"],
  },
  {
    name: "Build up",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D2950"],
  },
  {
    name: "MODL",
    category: "AMALGAM",
    surfaces: ["M", "O", "D", "L"],
    adaCodes: ["D2161"],
  },
  {
    name: "M",
    category: "ANT_COMPOSITE",
    surfaces: ["M"],
    adaCodes: ["D2330"],
  },
  {
    name: "SDF - Caries",
    category: "POST_COMPOSITE",
    surfaces: ["O"],
    adaCodes: ["D1354"],
  },
  {
    name: "Max Bridge",
    category: "CROWNS_OR_BRIDGES",
    surfaces: ["O"],
    adaCodes: ["D8220", "D7997"],
  },
  {
    name: "Crown GV",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D2928", "D2950"],
  },
  {
    name: "DOL",
    category: "AMALGAM",
    surfaces: ["O", "D", "L"],
    adaCodes: ["D2160"],
  },
  {
    name: "SDF - Preventative",
    category: "POST_COMPOSITE",
    adaCodes: ["D1355"],
  },
  {
    name: "D",
    category: "ANT_COMPOSITE",
    surfaces: ["D"],
    adaCodes: ["D2330"],
  },
  {
    name: "O",
    category: "AMALGAM",
    surfaces: ["O"],
    adaCodes: ["D2140"],
  },
  {
    name: "All on 4",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D6010", "D5810"],
  },
  {
    name: "Sealant",
    category: "POST_COMPOSITE",
    adaCodes: ["D1351"],
  },
  {
    name: "SSC",
    category: "POST_COMPOSITE",
    adaCodes: ["D2930"],
  },
  {
    name: "Crown Sup",
    category: "CROWNS_OR_BRIDGES",
    adaCodes: ["D2792"],
  },
  {
    name: "B5",
    category: "POST_COMPOSITE",
    surfaces: ["BV"],
    adaCodes: ["D2391"],
  },
];
