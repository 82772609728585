import { createForm } from "@libs/api/forms/mutations";
import { AppointmentCategoryRequest, PracticeVO } from "@libs/api/generated-api";
import { batchUpsertFrequencyLimitationCategories } from "@libs/api/practiceInsurance/mutations";
import { upsertAppointmentCategories } from "@libs/api/scheduling/appointmentCategories/mutations";
import { useCallback } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { formatAsISODate } from "@libs/utils/date";
import { addFeeSchedule } from "api/feeSchedule/mutations";
import { upsertPublicHolidays } from "api/scheduling/mutations";
import {
  useBootstrapDefaultForm,
  useBootstrapJourneyActionsMutation,
  useBootstrapProcedureShortcuts,
} from "components/CreatePractice/api/mutations";
import APPT_CATEGORIES_JSON from "components/CreatePractice/api/data/appointmentCategories.json";
import PUBLIC_HOLIDAYS_JSON from "components/CreatePractice/api/data/publicHolidays.json";

const APPT_CATEGORIES = APPT_CATEGORIES_JSON as AppointmentCategoryRequest[];

import { getDefaultForms } from "components/CreatePractice/api/getDefaultForms";
import { defaultCustomForms } from "components/CreatePractice/api/defaultCustomForms";
import { defaultProcedureShortcuts } from "components/CreatePractice/api/defaultProcedureShortcuts";
import { defaultJourneyActions } from "components/CreatePractice/api/journeyActions";
import { defaultFrequencyLimitationCategories } from "./frequencyLimitationCategories";

export const useBootstrapPractice = () => {
  const [
    saveAppointmentCategories,
    createFeeSchedule,
    createPublicHolidays,
    createFrequencyLimitationCategories,
    createFormMutation,
  ] = useApiMutations([
    upsertAppointmentCategories,
    addFeeSchedule,
    upsertPublicHolidays,
    batchUpsertFrequencyLimitationCategories,
    createForm,
  ]);
  const createFeeScheduleMutateAsync = createFeeSchedule.mutateAsync;
  const saveAppointmentCategoriesMutateAsync = saveAppointmentCategories.mutateAsync;
  const createPublicHolidaysMutateAsync = createPublicHolidays.mutateAsync;
  const createFrequencyLimitationCategoriesMutateAsync = createFrequencyLimitationCategories.mutateAsync;
  const createFormMutateAsync = createFormMutation.mutateAsync;
  const bootstrapJourneyActions = useBootstrapJourneyActionsMutation();
  const createBoostrapJourneyActionsMutateAsync = bootstrapJourneyActions.mutateAsync;
  const bootstrapDefaultForm = useBootstrapDefaultForm();
  const bootstrapDefaultFormMutateAsync = bootstrapDefaultForm.mutateAsync;
  const bootstrapProcedureShortcuts = useBootstrapProcedureShortcuts();
  const bootstrapProcedureShortcutsMutateAsync = bootstrapProcedureShortcuts.mutateAsync;

  const isLoading = [
    saveAppointmentCategories,
    createFeeSchedule,
    createPublicHolidays,
    createFrequencyLimitationCategories,
    createFormMutation,
    bootstrapJourneyActions,
    bootstrapDefaultForm,
    bootstrapProcedureShortcuts,
  ].some((mutation) => mutation.isLoading);
  const handleBootstrapPractice = useCallback(
    async (practiceId: number, onboardingType: NonNullable<PracticeVO["onboardingType"]>) => {
      const now = new Date();

      await Promise.all([
        onboardingType === "STARTUP"
          ? saveAppointmentCategoriesMutateAsync({
              practiceId,
              data: APPT_CATEGORIES,
            })
          : undefined,
        createFeeScheduleMutateAsync({
          practiceId,
          data: {
            name: "Office Fees",
            dentalProcedureFees: [],
            carrierIds: [],
            providerIds: [],
            startDate: formatAsISODate(now),
            type: "PRACTICE_UCR",
          },
        }),
        ...PUBLIC_HOLIDAYS_JSON.map((holidays) =>
          createPublicHolidaysMutateAsync({
            practiceId,
            data: holidays,
          })
        ),
        createFrequencyLimitationCategoriesMutateAsync({
          practiceId,
          data: {
            requests: defaultFrequencyLimitationCategories,
          },
        }),
        createBoostrapJourneyActionsMutateAsync({
          practiceId,
          data: {
            requests: defaultJourneyActions,
          },
        }),
        ...getDefaultForms(onboardingType).map((form) => {
          return bootstrapDefaultFormMutateAsync({
            practiceId,
            data: form,
          });
        }),
        defaultCustomForms.map((form) =>
          createFormMutateAsync({
            practiceId,
            data: form,
          })
        ),
        bootstrapProcedureShortcutsMutateAsync({
          practiceId,
          data: {
            requests: defaultProcedureShortcuts,
          },
        }),
      ]);
    },
    [
      bootstrapDefaultFormMutateAsync,
      bootstrapProcedureShortcutsMutateAsync,
      createBoostrapJourneyActionsMutateAsync,
      createFeeScheduleMutateAsync,
      createFormMutateAsync,
      createFrequencyLimitationCategoriesMutateAsync,
      createPublicHolidaysMutateAsync,
      saveAppointmentCategoriesMutateAsync,
    ]
  );

  return {
    handleBootstrapPractice,
    isLoading,
  };
};
