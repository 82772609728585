import startupDentalHistoryForm from "./data/startup/defaultDentalHistoryForm.json";
import startupMedicalHistoryForm from "./data/startup/defaultMedicalHistoryForm.json";
import conversionMedicalHistoryForm from "./data/conversion/defaultMedicalHistoryForm.json";
import conversionDentalHistoryForm from "./data/conversion/defaultDentalHistoryForm.json";
import consentForm from "./data/defaultConsentForm.json";
import defaultConsentForm from "./data/defaultTreatmentPlanForm.json";

export function getDefaultForms(onboardingType: "STARTUP" | "CONVERSION") {
  const forms: Record<string, unknown>[] = [consentForm, defaultConsentForm];

  if (onboardingType === "CONVERSION") {
    forms.push(conversionMedicalHistoryForm, conversionDentalHistoryForm);
  } else {
    forms.push(startupMedicalHistoryForm, startupDentalHistoryForm);
  }

  return forms;
}
