import { PublicHolidayVO } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const upsertPublicHolidays = makeMutation({
  mutationKey: ["practices", "upsertPublicHolidays"],
  formatParams: (args: { practiceId: number; data: PublicHolidayVO[] }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPublicHolidaysByYear"), { practiceId }]);
    },
  }),
});
