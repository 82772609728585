import { AddressVO, PracticeVO } from "@libs/api/generated-api";
import { components, OptionProps } from "react-select";

import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { getTimezoneName } from "@libs/utils/date";
import { AddressInputReadOnly } from "@libs/components/UI/AddressInputReadOnly";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { useItemModal } from "@libs/hooks/useItemModal";
import { getBusinessInformationSchema } from "@libs/utils/getBusinessInformationSchema";
import { useValidation } from "@libs/hooks/useValidation";
import { produce } from "immer";
import { Form } from "@libs/components/UI/Form";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { AddressInput } from "@libs/components/UI/AddressInput";
import { SettingsValue } from "@libs/components/UI/SettingsComponents";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { cx } from "@libs/utils/cx";
import { RadioList } from "@libs/components/UI/RadioList";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { getEmptyAddress } from "@libs/utils/address";
import { getPracticeInfoSchema } from "@libs/utils/validationSchemas";
import { FormFieldTaxId } from "@libs/components/UI/FormFieldTaxId";
import { AdminPanel } from "components/UI/AdminPanel";
import { updatePractice, updatePracticeEin } from "api/practice/mutations";
import { OnboardingStatus } from "utils/onboardingStatus";
import { useEnvContext } from "contexts/EnvContext";
import { handleError } from "utils/handleError";

type DefinedOnboardingStatus = NonNullable<OnboardingStatus>;

const ENABLED_OPTIONS_FOR_STEP: Record<DefinedOnboardingStatus, Set<DefinedOnboardingStatus>> = {
  PROVISIONING: new Set(["PROVISIONING"]),
  ONBOARDING: new Set(["ONBOARDING", "READY", "CHURNED"]),
  READY: new Set(["ONBOARDING", "READY", "CHURNED"]),
  LAUNCHED: new Set(["LAUNCHED", "CHURNED"]),
  CHURNED: new Set(["ONBOARDING", "READY", "LAUNCHED", "CHURNED"]),
};

const STATUS_OPTION_ORDER: DefinedOnboardingStatus[] = [
  "PROVISIONING",
  "ONBOARDING",
  "READY",
  "LAUNCHED",
  "CHURNED",
];

const ONBOARDING_STATUS_TO_DESCRIPTION: Record<DefinedOnboardingStatus, string> = {
  PROVISIONING: "The practice needs to provide basic information",
  ONBOARDING: "Archy Engineers are working to migrate data into this practice",
  READY: "The practice is set up and ready to start using Archy",
  LAUNCHED: "The practice has completed 5 appointments and billing has begun",
  CHURNED: "The practice has decided to close their Archy account",
};

const PRACTICE_TYPE_OPTIONS: OptionInputOption<NonNullable<PracticeVO["onboardingType"]>>[] = [
  { value: "STARTUP", label: "Startup" },
  { value: "CONVERSION", label: "Conversion" },
];

interface OnboardingStatusOption extends SelectOption<DefinedOnboardingStatus> {
  description: string;
}

const OnboardingStatusMenuOption = (
  props: OptionProps<
    OnboardingStatusOption,
    false,
    GroupedSelectOption<DefinedOnboardingStatus, OnboardingStatusOption>
  >
) => (
  <components.Option {...props}>
    <div className="flex flex-col" style={{ fontWeight: 400 }}>
      <div className={cx(props.isSelected && "text-archyBlue-500 font-normal")}>{props.label}</div>
      <div className={cx("text-xs", props.isDisabled && "text-slate-400 font-normal")}>
        {props.data.description}
      </div>
    </div>
  </components.Option>
);

// In order for useValidation to work, a nested object
// that is validated must be initialized with a value
const resetDraft = (
  practice: PracticeVO
): Omit<PracticeVO, "physicalAddress" | "billingAddress"> & {
  physicalAddress: AddressVO;
  billingAddress: AddressVO;
} => ({
  ...practice,
  billingAddress: practice.billingAddress ?? getEmptyAddress(),
  physicalAddress: practice.physicalAddress ?? getEmptyAddress(),
});

export const PracticeInfoForm: React.FC<{ practice: PracticeVO; edit: boolean; onCloseEditor: Func }> = ({
  practice,
  edit,
  onCloseEditor,
}) => {
  usePageTitle(`${practice.id}: ${practice.doingBusinessAs}`);

  const [practiceDraft, setPracticeDraft] = useState(() => resetDraft(practice));
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();

  const schema = useMemo(() => {
    const practiceInfoSchema = getPracticeInfoSchema();

    return {
      website: practiceInfoSchema.website,
      ...getBusinessInformationSchema({
        isBillingAddressSame: practiceDraft.isBillingAddressSame,
        taxIdLastFour: practice.taxIdLastFour,
      }),
    };
  }, [practiceDraft.isBillingAddressSame, practice.taxIdLastFour]);

  const { result, validate } = useValidation(practiceDraft, schema);

  const confirmingChange = useItemModal<DefinedOnboardingStatus>(null);
  const [
    { mutate: updatePracticeMutate, isLoading: isUpdatingPractice },
    { mutate: updateEinMutate, isLoading: isUpdatingTaxId },
  ] = useApiMutations([updatePractice, updatePracticeEin]);
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!practice.id || !validate().$isValid) {
        return;
      }

      updatePracticeMutate(
        {
          data: practiceDraft,
          practiceId: practice.id,
        },
        {
          onError: handleError,
          onSuccess: onCloseEditor,
        }
      );

      if (practiceDraft.taxId && practiceDraft.taxId !== practice.taxId) {
        updateEinMutate(
          { data: { taxId: practiceDraft.taxId }, practiceId: practice.id },
          {
            onError: handleError,
          }
        );
      }
    },
    [
      onCloseEditor,
      practice.id,
      practice.taxId,
      practiceDraft,
      updateEinMutate,
      updatePracticeMutate,
      validate,
    ]
  );
  const statusOptions: OnboardingStatusOption[] = useMemo(() => {
    return STATUS_OPTION_ORDER.map((status) => ({
      label: sentenceCaseConstant(status),
      value: status,
      description: ONBOARDING_STATUS_TO_DESCRIPTION[status],
      isDisabled: !ENABLED_OPTIONS_FOR_STEP[practice.onboardingStatus ?? "LAUNCHED"].has(status),
    }));
  }, [practice.onboardingStatus]);

  const handleConfirmStatusChange = useCallback(() => {
    if (confirmingChange.item) {
      setPracticeDraft((last) =>
        produce(last, (draft) => {
          draft.onboardingStatus = confirmingChange.item;
        })
      );
    }

    confirmingChange.close();
  }, [confirmingChange, setPracticeDraft]);
  const tz = practiceDraft.timezoneId;

  return (
    <>
      <Form className="flex flex-col h-full min-h-0" onSubmit={handleSubmit}>
        <AdminPanel>
          <p className="font-sansSemiBold text-sm">Status</p>
          <div className="grid grid-cols-2 gap-6">
            {edit ? (
              <RadioList
                label="Type"
                layout="horiz"
                selectedValue={practiceDraft.onboardingType}
                onChange={(_e, option) => {
                  setPracticeDraft((last) =>
                    produce(last, (draft) => {
                      draft.onboardingType = option.value;
                    })
                  );
                }}
                options={PRACTICE_TYPE_OPTIONS}
                optionClassName="font-sans"
                className="flex-1"
              />
            ) : (
              <FormFieldInput
                label="Type"
                value={practice.onboardingType ? sentenceCaseConstant(practice.onboardingType) : EMPTY_CELL}
                edit={false}
              />
            )}

            <FormFieldSelect
              label="Status"
              isSearchable={false}
              isClearable={false}
              display="label"
              value={practiceDraft.onboardingStatus}
              edit={edit}
              options={statusOptions}
              components={{ Option: OnboardingStatusMenuOption }}
              onItemSelected={(val) => {
                if (val !== practiceDraft.onboardingStatus) {
                  confirmingChange.open(val);
                }
              }}
            />
          </div>
          <p className="font-sansSemiBold text-sm">Details</p>
          <div className="grid grid-cols-2 gap-6">
            <FormFieldInput
              label="Doing Business As"
              error={result.doingBusinessAs.$error}
              autoComplete="false"
              edit={edit}
              required
              value={practiceDraft.doingBusinessAs}
              onChange={(e) => {
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.doingBusinessAs = e.target.value;
                  })
                );
              }}
            />
            <FormFieldInput
              label="Legal Company Name"
              autoComplete="false"
              edit={edit}
              error={result.name.$error}
              value={practiceDraft.name}
              required
              onChange={(e) => {
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.name = e.target.value;
                  })
                );
              }}
            />
            <FormFieldTaxId
              label="Tax ID"
              taxIdLastFour={practice.taxIdLastFour}
              edit={edit}
              value={practiceDraft.taxId}
              // Since taxId is never returned we can only tell if a user has saved a taxId
              // by checking if taxIdLastFour exists. If it does, the field, taxId, is not required.
              // Otherwise, the user must enter a taxId.
              required={!practice.taxIdLastFour}
              error={result.taxId.$error}
              onValueChange={(taxId: string) => {
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.taxId = taxId;
                  })
                );
              }}
            />
            <FormFieldInput
              label="Website"
              autoComplete="false"
              edit={edit}
              value={practiceDraft.website}
              required
              error={result.website.$error}
              onChange={(e) => {
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.website = e.target.value;
                  })
                );
              }}
            />
            <FormFieldInput
              label="Timezone"
              edit={false}
              value={tz ? `${getTimezoneName(tz)} (${tz})` : undefined}
            />

            <div />
          </div>
          {!edit && <AddressInputReadOnly label="Practice Address" address={practice.physicalAddress} />}
          {!edit && (
            <AddressInputReadOnly
              label="Billing Address"
              address={practice.isBillingAddressSame ? practice.physicalAddress : practice.billingAddress}
            />
          )}

          {edit && (
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12 text-sm font-sansSemiBold mt-1">Practice Address</div>
              <AddressInput
                apiKey={REACT_APP_GOOGLE_API_KEY}
                address={practiceDraft.physicalAddress}
                validation={result.physicalAddress}
                onChange={(address) => {
                  setPracticeDraft((state) => ({
                    ...state,
                    physicalAddress: address,
                  }));
                }}
              />
              <div className="col-span-8">
                <SettingsValue>Billing Address (Also used for claims)</SettingsValue>
              </div>
              <div className="col-span-8 mb-4">
                <Checkbox
                  checked={Boolean(practiceDraft.isBillingAddressSame)}
                  onChange={(e) => {
                    setPracticeDraft((state) => ({
                      ...state,
                      isBillingAddressSame: e.target.checked,
                    }));
                  }}
                >
                  Same as practice address
                </Checkbox>
              </div>
              {practiceDraft.isBillingAddressSame ? null : (
                <AddressInput
                  apiKey={REACT_APP_GOOGLE_API_KEY}
                  address={practiceDraft.billingAddress}
                  validation={result.billingAddress}
                  onChange={(address) => {
                    setPracticeDraft((state) => ({ ...state, billingAddress: address }));
                  }}
                />
              )}
            </div>
          )}
        </AdminPanel>
        {edit && (
          <div
            className={`
              flex
              gap-6
              justify-center
              flex-none
              p-4
              border-t
              border-t-slate-200
            `}
          >
            <Button onClick={onCloseEditor} theme="secondary" className="min-w-button">
              Cancel
            </Button>
            <AsyncButton
              isLoading={isUpdatingPractice || isUpdatingTaxId}
              type="submit"
              theme="primary"
              className="min-w-button"
            >
              Save
            </AsyncButton>
          </div>
        )}
      </Form>
      {confirmingChange.isOpen && (
        <ConfirmationModal
          primaryText={`Are you sure you want to change the status to ${sentenceCaseConstant(
            confirmingChange.item
          )}?`}
          secondaryText="Changing a status can affect the practice's access to certain features."
          confirmText="I'm sure"
          isConfirming={isUpdatingPractice}
          onConfirm={handleConfirmStatusChange}
          onCancel={confirmingChange.close}
          size="xs"
        />
      )}
    </>
  );
};
