import { FormRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const createForm = makeMutation({
  mutationKey: ["practices", "createForm"],
  formatParams: (args: { practiceId: number; data: FormRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFormSummaries"), { practiceId }]);
    },
  }),
});
