import { getQueryKey } from "@libs/utils/queries";
import { AppointmentCategoryRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";

export const upsertAppointmentCategories = makeMutation({
  mutationKey: ["practices", "upsertAppointmentCategories"],
  formatParams: (args: { practiceId: number; data: AppointmentCategoryRequest[] }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      queryClient.setQueryData([getQueryKey("practices", "getAppointmentCategories"), { practiceId }], data);
      queryClient.invalidateQueries([getQueryKey("practices", "getSchedulingConfig"), { practiceId }]);
    },
  }),
});
