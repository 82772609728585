import { PracticeMigrationSettingsRequest, PracticeMigrationSettingsVO } from "@libs/api/generated-api";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Form } from "@libs/components/UI/Form";
import { FormFieldNumberInput } from "@libs/components/UI/FormFieldNumberInput";
import { useObjectState } from "@libs/hooks/useObjectState";
import { FormEvent, useCallback, useMemo } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { Options } from "react-select";
import { upsertPracticeMigrationSettings } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { AdminPanel } from "components/UI/AdminPanel";
import { useSegmentLogger } from "contexts/AdminSegmentContext";

type FeeScheduleMigrationOption = NonNullable<PracticeMigrationSettingsVO["migrateFeeSchedules"]>;

const getDefaultDraft = (
  settings: Omit<PracticeMigrationSettingsVO, "practiceId" | "practiceUuid">
): PracticeMigrationSettingsRequest => {
  return {
    migrateBalances: settings.migrateBalances,
    migrateCustomCodes: settings.migrateCustomCodes,
    migrateInsuranceCodes: settings.migrateInsuranceCodes,
    migrateFeeSchedules: settings.migrateFeeSchedules,
    migrationInactivePatientDataInYears: settings.migrationInactivePatientDataInYears,
  };
};

const MIGRATE_FEE_SCHEDULES_OPTIONS: Options<SelectOption<FeeScheduleMigrationOption>> = [
  {
    value: "YES_ALL",
    label: "All",
  },
  {
    value: "YES_ONLY_UCR",
    label: "Only UCR",
  },
  {
    value: "NO",
    label: "No",
  },
];

const YES_NO_OPTIONS: Options<SelectOption<"YES" | "NO">> = [
  {
    value: "YES",
    label: "Yes",
  },
  {
    value: "NO",
    label: "No",
  },
];

export const MigrationSettingsForm: React.FC<{
  settings: Omit<PracticeMigrationSettingsVO, "practiceId" | "practiceUuid">;
  edit: boolean;
  onCloseEditor: Func;
  practiceId: number;
}> = ({ settings, onCloseEditor, edit, practiceId }) => {
  const [draft, updateDraft] = useObjectState<PracticeMigrationSettingsRequest>(() =>
    getDefaultDraft(settings)
  );
  const [upsertMigrationSettingsMutation] = useApiMutations([upsertPracticeMigrationSettings]);
  const upsertSettingsMutate = upsertMigrationSettingsMutation.mutate;
  const sharedProps = { edit };
  const selectProps = {
    ...sharedProps,
    placeholder: "Select an option...",
    display: "label" as const,
    isClearable: true,
    isSearchable: false,
  };
  const settingsValue = useMemo((): PracticeMigrationSettingsRequest => {
    return edit ? draft : settings;
  }, [draft, edit, settings]);
  const {
    migrateBalances,
    migrateCustomCodes,
    migrateInsuranceCodes,
    migrateFeeSchedules,
    migrationInactivePatientDataInYears,
  } = settingsValue;
  const logger = useSegmentLogger();
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      upsertSettingsMutate(
        { practiceId, data: draft },
        {
          onSuccess: () => {
            logger.track(
              {
                event: "Updated Migration Settings",
                practiceId,
                domains: ["Migration Settings"],
              },
              {
                properties: draft,
              }
            );
            onCloseEditor();
          },
          onError: handleError,
        }
      );
    },
    [draft, logger, onCloseEditor, practiceId, upsertSettingsMutate]
  );

  return (
    <Form className="flex flex-col h-full min-h-0 pt-8" onSubmit={handleSubmit}>
      <AdminPanel>
        <div className="grid grid-cols-2 gap-6">
          <FormFieldSelect
            {...selectProps}
            label="Migrate Fee Schedules"
            options={MIGRATE_FEE_SCHEDULES_OPTIONS}
            placeholder="Select Fee Schedules"
            value={migrateFeeSchedules}
            onChange={(option) => {
              updateDraft({ migrateFeeSchedules: option?.value });
            }}
          />
          <FormFieldNumberInput
            {...sharedProps}
            label="Number of years of patient data to migrate"
            placeholder="Enter number of years"
            onValueChange={(newValue) => updateDraft({ migrationInactivePatientDataInYears: newValue })}
            value={migrationInactivePatientDataInYears}
            step={1}
          />
          <FormFieldSelect
            {...selectProps}
            label="Migrate Balances"
            value={migrateBalances}
            options={YES_NO_OPTIONS}
            onChange={(option) => {
              updateDraft({ migrateBalances: option?.value });
            }}
          />
          <FormFieldSelect
            {...selectProps}
            label="Migrate Custom Codes"
            value={migrateCustomCodes}
            options={YES_NO_OPTIONS}
            onChange={(option) => {
              updateDraft({ migrateCustomCodes: option?.value });
            }}
          />
          <FormFieldSelect
            {...selectProps}
            label="Migrate Insurance Codes"
            value={migrateInsuranceCodes}
            options={YES_NO_OPTIONS}
            onChange={(option) => {
              updateDraft({ migrateInsuranceCodes: option?.value });
            }}
          />
        </div>
      </AdminPanel>
      {edit && (
        <div
          className={`
            flex
            gap-6
            justify-center
            flex-none
            p-4
            border-t
            border-t-slate-200
          `}
        >
          <Button onClick={onCloseEditor} theme="secondary" className="min-w-button">
            Cancel
          </Button>
          <AsyncButton
            isLoading={upsertMigrationSettingsMutation.isLoading}
            type="submit"
            theme="primary"
            className="min-w-button"
          >
            Save
          </AsyncButton>
        </div>
      )}
    </Form>
  );
};
