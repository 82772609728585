// For accessing hidden requests

import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { ContentType } from "@libs/api/generated-api";
import { AutomationJourneyRequest } from "components/CreatePractice/api/journeyActions";
import { BootstrapDefaultProcedureShortcutRequest } from "components/CreatePractice/api/defaultProcedureShortcuts";

const getBootstrapMutationKey = (api: string) => ["bootstrap", api];

export const useBootstrapJourneyActionsMutation = () => {
  const { httpClient } = useContext(ApiClientContext);

  return useMutation({
    mutationKey: getBootstrapMutationKey("createJourneyActions"),
    mutationFn: async (params: { practiceId: number; data: { requests: AutomationJourneyRequest[] } }) => {
      return httpClient.request({
        method: "POST",
        path: `/practices/${params.practiceId}/journeys`,
        body: params.data,
        type: ContentType.Json,
      });
    },
  });
};

export const useBootstrapDefaultForm = () => {
  const { httpClient } = useContext(ApiClientContext);

  return useMutation({
    mutationKey: getBootstrapMutationKey("createDefaultForm"),
    mutationFn: async (params: { practiceId: number; data: Record<string, unknown> }) => {
      return httpClient.request({
        method: "POST",
        path: `/practices/${params.practiceId}/default-forms`,
        body: params.data,
        type: ContentType.Json,
      });
    },
  });
};

export const useBootstrapProcedureShortcuts = () => {
  const { httpClient } = useContext(ApiClientContext);

  return useMutation({
    mutationKey: getBootstrapMutationKey("createDefaultProcedureShortcuts"),
    mutationFn: async (params: {
      practiceId: number;
      data: { requests: BootstrapDefaultProcedureShortcutRequest[] };
    }) => {
      return httpClient.request({
        method: "POST",
        path: `/practices/${params.practiceId}/default-procedure-shortcuts`,
        body: params.data,
        type: ContentType.Json,
      });
    },
  });
};
