import { Pill, PillProps } from "@libs/components/UI/Pill";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { FC } from "react";
import { OnboardingStatus } from "utils/onboardingStatus";

const onboardingStatusToColor: Record<NonNullable<OnboardingStatus>, PillProps["theme"]> = {
  LAUNCHED: "green",
  READY: "blue",
  ONBOARDING: "orange",
  PROVISIONING: "yellow",
  CHURNED: "slate100",
};

export const OnboardingStatusPill: FC<{ status: OnboardingStatus }> = ({ status }) => {
  return (
    <Pill className="w-fit" round={false} theme={onboardingStatusToColor[status ?? "LAUNCHED"]}>
      {sentenceCaseConstant(status ?? "LAUNCHED")}
    </Pill>
  );
};
